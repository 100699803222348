.inline-text {
  display: inline!important;
}

.song-row {
    display: flex;
    justify-content: space-between;
    margin: 0 .25rem .5rem .25rem;
}

@media (max-width: 451px) {
    .song-row {
        justify-content: center;
        padding-bottom: 1rem;
    }
}

.song-title {
  min-width: 270px;
  padding-left: 0;
  padding-right: 0;
  word-wrap: normal;
  white-space: normal;
}

@media (max-width: 451px) {
    .song-title {
        padding-bottom: .35rem;
    }
}
