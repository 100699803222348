.dot {
  background-color: #000;
  border-radius: 50% !important;
  cursor: pointer;
  display: inline-block;
  height: 12px;
  margin-bottom: 6px;
  position: absolute;
  top: -90%;
  width: 12px;
}

.player {
  align-items: center;
  display: flex;
  float: right;
  max-width: 200px;
  min-width: 200px;
  text-align: center;
}

.progress-bar {
  background-color: #000 !important;
  border-radius: 2px !important;
  float: left;
  height: 4px;
  width: 100%;
}
