.arrow-ghost {
  width: 35px;
}

.bibliography {
  max-width: 2000px;
  margin: auto;
}

.date {
  width: 110px;
}

.pub-header {
  background-color: #FFF;
  display: flex;
  margin: 0;
  padding: .5rem .5rem .2rem .5rem;
  /*position: sticky;*/
  top: 0;
  width: 100%;
  z-index: 999;
}

@media (max-width: 768px) {
  .pub-header {
    background-color: #F9F9F9!important;
    padding-top: 0;
    top: 50px;
    width: 100%;
  }
}
