.dropdown-abstract {
  padding-bottom: .5rem;
  width: 100%;
}

@media (min-width: 1200px) {
  .dropdown-abstract {
    padding-right: .5rem;
    width: 50%;
  }
}

.dropdown-citation {
  width: 100%;
}

@media (min-width: 1200px) {
  .dropdown-citation {
    padding-bottom: .5rem;
    padding-left: .5rem;
    width: 50%;
  }
}

.arrow {
  min-width: 35px;
  max-width: 35px;
}

.date {
  width: 110px;
}

.extrasbox {
  padding: .25rem 0 0 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .extrasbox {
    padding: 0;
  }
}

.iconbox {
  background-color: #F9F9F9!important;
  color: #000 !important;
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: center;
  width: 100%;
}

.iconbox:hover {
  color: #FFF !important;
  background-color: #000 !important;
}

.indent {
  padding-left: 1.8rem;
}

.inline-text {
  display: inline!important;
}

.pad-large {
  padding-left: .5rem;
}

@media (max-width: 576px) {
  .pad-large {
    padding-left: 0!important;
  }
}

.pointer {
  cursor: pointer;
}

.line-height-140 {
  line-height: 140% !important;
}

.line-height-100 {
  line-height: 100%;
}
