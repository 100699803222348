h3 {
    margin-bottom: .25rem;
}

h4 {
    margin-bottom: .05rem;
}

h5 {
    margin-bottom: 0;
}

p {
    margin-bottom: 0rem;
}

.audio-single {
  display: block;
  margin: .7rem auto;
  width: 100%;
  max-width: 320px;
}

.audio-title {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}

.caption {
  font-style: italic;
  font-size: 16px;
  line-height: 140%;
  margin: auto;
  text-align: center;
}

.download {
  display: block;
  margin: 1rem 0 1rem 0;
  padding: 1rem 0 1rem 0;
  width: 100%;
}

.imagebox {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.image {
  display: block;
  margin: .3rem auto;
  width: 100%;
  max-width: 650px;
  height: auto;
}

.spacer {
  margin-bottom: 1rem;
}

.simple-table {
  table-layout: fixed;
  margin: .7rem auto;
  width: 100%;
  max-width: 900px;
  text-align: center;
}

.simple-table-cell {
  width: 100%;
  max-width: 200px;
}
