.iconbox {
  color: #000 !important;
  background-color: #F9F9F9!important;
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.iconbox:hover {
  color: #FFF !important;
  background-color: #000 !important;
}

.line-height-100 {
  line-height: 100%;
}

.text-16 {
  font-size: 16px;
}

.text-24 {
  font-size: 22px;
}

/******************************************************************************
Cards
******************************************************************************/

.project-card {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 1rem;
}

@media (min-width: 675px) and (max-width: 768px) {
  .project-card {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .project-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .project-card {
      flex: 0 0 50%;
      max-width: 50%;
  }
}

@media (min-width: 1200px) {
  .project-card {
      flex: 0 0 33%;
      max-width: 33%;
  }
}

.card-text {
  text-align: justify;
  font-size: 16px;
}

@media (min-width: 1000px) and (max-width: 1894px) {
  .card-text {
    min-height: 48px;
  }
}

@media (min-width: 675px) and (max-width: 768px) {
  .card-title {
    min-height: 70px;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .card-title {
    min-height: 37px;
  }
}


@media (min-width: 1000px) and (max-width: 1058px) {
  .card-title {
    min-height: 70px;
  }
}

@media (min-width: 1058px) and (max-width: 1200px) {
  .card-title {
    min-height: 37px;
  }
}

@media (min-width: 1200px) and (max-width: 1472px) {
  .card-title {
    min-height: 70px;
  }
}

.card-footer {
  background-color: #FFFFFF !important;
  border-width: 0px !important;
  display: flex;
  padding: 0;
  text-align: center;
}
