h3 {
    margin-bottom: .25rem;
}

h4 {
    margin-bottom: .05rem;
}

h5 {
    margin-bottom: 0;
}

p {
    margin-bottom: 0rem;
}

.abstract {
  padding: 0rem 0 1.4rem 0;
}

.abstract p {
  font-size: 16px;
  margin: 0;
  line-height: 140%
}

.author {
  display: inline-block;
  font-size: 18px;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  text-align: center;
}

.citations {
  margin-top: 1.4rem;
}

.iconbox {
  background-color: #F9F9F9!important;
  color: #000 !important;
  padding-top: .5rem;
  padding-bottom: .5rem;
  text-align: center;
  width: 100%;
}

.iconbox:hover {
  color: #FFF !important;
  background-color: #000 !important;
}

.indent {
  padding-left: 1.8rem;
}

.line-height-100 {
  line-height: 100%;
}

.links {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 1.8rem 0;
  width: 100%;
}

.project {
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 0.8rem;
}

.section {
  font-size: 16px;
  line-height: 140%;
  padding: 1.4rem 0;
}

.subsection {
  margin-bottom: .7rem;
}

.title {
  margin-top: 1rem;
}
