img.selfie {
  display: block;
  margin: 0 auto;
  max-width: 80%;
}

.news-date {
  min-width: 105px;
  vertical-align: top;
  text-align: right;
  padding-right: .2em;
}

.break {
  line-height: 100%;
}

.home {
  line-height: 150%;
  max-width: 1110px !important;
  margin: auto;
  padding-top: 28px !important;
}

@media (max-width: 768px) {
  .home {
    padding-top: 18px !important;
  }
}
