.audio-table {
  display: table;
  height: 35px;
  margin: 0 auto;
  margin-bottom: .1rem;
  max-width: 400px;
  min-width: 50px;
  width: 100%;
}

.column-text {
  text-align: center;
}

.small-column {
  width: 9em;
  max-width: 9em;
}

.wavtable {
  font-size: 1rem;
  margin: 1rem auto 0 auto;
  width: 100%;
  table-layout: fixed;
}

.wav-entry {
  text-align: center;
  vertical-align: middle;
}
