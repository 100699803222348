.container {
  max-width: none;
  width: 100% !important;
}

.pad-left-250 {
  padding-left: 250px;
}

@media (max-width: 768px) {
  .container {
    margin: 0;
    padding: 0;
  }
}

.fix-width-icon {
  font-size: 17px;
  max-width: 28px;
  min-width: 28px;
}

.pub-icon {
  background-color: #F9F9F9!important;
  color: #000 !important;
  cursor: pointer;
  font-size: 26px;
  padding: .3rem .85rem .3rem .85rem;
  transition: all 0.2s;
  width: 53px;
}

.pub-icon:hover {
  background-color: #000 !important;
  color: #F9F9F9 !important;
}

.social-button {
  color: inherit;
}

.social-button:hover {
  color: inherit;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-20 {
  font-size: 20px;
}

.text-25-5 {
  font-size: 25.5px;
}

.text-28 {
  font-size: 28px;
}

.text-tight {
  line-height: 125%;
}

/******************************************************************************
Sidebar
******************************************************************************/

.allbar {
  position: absolute;
  left: calc(50% - 125px);
}

.collapsable {
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 768px) {
  .collapsable {
    margin-left: -250px;
  }
}

.hide {
  display: none;
}

.sidebar-row {
  margin: 0;
  padding-top: 0.5rem!important;
  padding-bottom: 0.5rem!important;
}

#sidebar {
  background: #fff;
  color: #000;
  float: left;
  height: 100vh;
  min-width: 250px;
  max-width: 250px;
  padding: 0;
  transition: all 0.2s;
  width: 250px;
  z-index: 999;
}

#sidebar .sidebar-header{
  background: #fff;
  color: #000;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  text-align: center;
  width: 100%;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  color: inherit;
  display: block;
  padding: 10px 28px;
  transition: all 0.2s;
}

#sidebar ul li a:hover {
  background: #000;
  color: #fff;
}

#sidebar .footer {
 bottom: 0;
 left: 0;
 position: absolute;
 width: 100%;
}


/******************************************************************************
Topbar
******************************************************************************/


.topbar {
  background-color: #F9F9F9!important;
  display: none;
  height: 50px;
  width: 100% !important;
  z-index: 999;
}


/******************************************************************************
Content
******************************************************************************/


.content {
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s;
}

.margin-top-50 {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .content {
    width: 100%;
  }
}

.width-minus-sidebar {
  width: calc(100% - 250px);
}
